import React, {Component} from "react"
import Layout from "../components/layout"
import {createClient} from 'contentful'
import "../styles.scss"

const client = createClient({
    space: 'r2byq82vk2mv',
//  environment: 'stagging', // defaults to 'master' if not set
    accessToken: 'EC5eqLe-RwUVy048Xaa0ftmTGYHSAjmvLhXfDkJjc3c'
});

var Loader = require('react-loader');

class GetHelp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blogPosts: [],
            screenLoaded: false
        }
    }

    componentDidMount() {
        window.analytics.page("Get Help");
        client.getEntries({
            content_type: 'post',
            limit: 2,
            skip: 0
        })
            .then(({items}) => {

                this.setState({
                    screenLoaded: true,
                    blogPosts: items
                })
            })
            .catch(error => {
                console.log(error);
            });
    }

    componentWillMount() {
    }

    renderPosts() {

        return this.state.blogPosts.map((blogPost) => {
            return (
                <Loader loaded={true}>
                    <article key={blogPost.sys.id}>
                        <h3>{blogPost.fields.title}</h3>
                        <p>{blogPost.fields.description}</p>
                    </article>
                </Loader>
            );
        });
    }

    render() {
        return (
            <Layout>
                <div>
                    <h2>Blog Posts</h2>
                    {this.renderPosts()}
                </div>
            </Layout>
        );
    }
}

export default GetHelp
